// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../utils/Utils.bs.js");
var React = require("react");
var ViewStack = require("../../reason/common/ViewStack.bs.js");
var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Image = require("../../reason/next/Next__Image.bs.js");
var Theme__Color = require("../../reason/common/Theme/Theme__Color.bs.js");
var NukaCarousel = require("nuka-carousel").default;
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Touchable__Jsx3 = require("../../uikit/reason/atoms/Touchable/Touchable__Jsx3.bs.js");
var UIKit__BaseDialog = require("../../uikit/reason/molecules/Dialog/UIKit__BaseDialog.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../reason/common/Theme/Theme__Color__Vars.bs.js");
var VisuallyHidden = require("@reach/visually-hidden").default;
var UseWindowSize = require("uikit/hooks/useWindowSize");

var defaultControlsConfig = {};

var Nuka = {
  defaultControlsConfig: defaultControlsConfig
};

function fromAttachment(param) {
  var key = param.key;
  return {
          fullscreen: Utils.makeAttachmentUrl(1200, undefined, undefined, undefined, 1, undefined, key),
          large: Utils.makeAttachmentUrl(1200, 800, undefined, true, undefined, undefined, key),
          small: Utils.makeAttachmentUrl(750, 500, undefined, true, undefined, undefined, key),
          thumb: Utils.makeAttachmentUrl(90, 90, true, undefined, undefined, undefined, key)
        };
}

var CarouselImg_width = {
  large: 1200,
  small: 750
};

var CarouselImg_height = {
  large: 800,
  small: 500
};

var CarouselImg = {
  width: CarouselImg_width,
  height: CarouselImg_height,
  fromAttachment: fromAttachment
};

var imageContainerStyle = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100
          }),
      tl: {
        hd: Css.height({
              NAME: "percent",
              VAL: 100
            }),
        tl: {
          hd: Css.position("relative"),
          tl: /* [] */0
        }
      }
    });

var fullscreenImageStyle = Curry._1(Css.style, {
      hd: Css.objectFit("contain"),
      tl: /* [] */0
    });

var imageStyle = Curry._1(Css.style, {
      hd: Css.objectFit("contain"),
      tl: {
        hd: Css.width({
              NAME: "percent",
              VAL: 100
            }),
        tl: {
          hd: Css.height("auto"),
          tl: /* [] */0
        }
      }
    });

function TMPUIKIT____ImgGallery$ResponsiveImage(props) {
  var imgSize = props.imgSize;
  var alt = props.alt;
  var image = props.image;
  var match = imgSize === "fullscreen" ? [
      image.fullscreen,
      1200,
      undefined
    ] : (
      imgSize === "small" ? [
          image.small,
          750,
          500
        ] : [
          image.large,
          1200,
          800
        ]
    );
  var src = match[0];
  if (imgSize === "fullscreen") {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(Next__Image.make, {
                      src: src,
                      fill: true,
                      unoptimized: true,
                      alt: alt,
                      className: fullscreenImageStyle
                    }),
                className: imageContainerStyle
              });
  } else {
    return JsxRuntime.jsx(Next__Image.make, {
                src: src,
                width: match[1],
                height: match[2],
                unoptimized: true,
                alt: alt,
                className: imageStyle
              });
  }
}

var ResponsiveImage = {
  imageContainerStyle: imageContainerStyle,
  fullscreenImageStyle: fullscreenImageStyle,
  imageStyle: imageStyle,
  make: TMPUIKIT____ImgGallery$ResponsiveImage
};

function thumbnailStyles(highlightSelected, sizeProp, isSelected) {
  return {
          hd: Css.transform({
                NAME: "scale",
                VAL: [
                  1,
                  1
                ]
              }),
          tl: {
            hd: Css.transitionProperty("all"),
            tl: {
              hd: Css.transitionTimingFunction("ease"),
              tl: {
                hd: Css.transitionDuration(100),
                tl: {
                  hd: Css.cursor("pointer"),
                  tl: {
                    hd: Css.media("(hover: hover)", {
                          hd: Css.hover({
                                hd: Css.transform({
                                      NAME: "scale",
                                      VAL: [
                                        1.1,
                                        1.1
                                      ]
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.fontSize({
                                  NAME: "px",
                                  VAL: 16
                                }),
                            tl: {
                              hd: Css.fontWeight("bold"),
                              tl: {
                                hd: Css.width({
                                      NAME: "px",
                                      VAL: sizeProp
                                    }),
                                tl: {
                                  hd: Css.height({
                                        NAME: "px",
                                        VAL: sizeProp
                                      }),
                                  tl: {
                                    hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                                              NAME: "num",
                                              VAL: 0.1
                                            })),
                                    tl: {
                                      hd: isSelected && highlightSelected ? Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, {
                                                  NAME: "px",
                                                  VAL: 3
                                                }, {
                                                  NAME: "px",
                                                  VAL: 1
                                                }, undefined, Theme__Color.stTropaz)) : Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, {
                                                  NAME: "px",
                                                  VAL: 3
                                                }, {
                                                  NAME: "px",
                                                  VAL: 1
                                                }, undefined, "transparent")),
                                      tl: {
                                        hd: Css.selector("img", {
                                              hd: Css.objectFit("cover"),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        };
}

function gridStyles(sizeProp) {
  return {
          hd: Css.display("grid"),
          tl: {
            hd: Css.gridTemplateColumns({
                  hd: {
                    NAME: "repeat",
                    VAL: [
                      "autoFit",
                      {
                        NAME: "px",
                        VAL: sizeProp
                      }
                    ]
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.padding2("zero", {
                    NAME: "px",
                    VAL: 16
                  }),
              tl: {
                hd: Css.gridGap({
                      NAME: "px",
                      VAL: 16
                    }),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.width({
                          NAME: "percent",
                          VAL: 100
                        }),
                    tl: {
                      hd: Css.marginBottom({
                            NAME: "px",
                            VAL: 16
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        };
}

function TMPUIKIT____ImgGallery$ImageThumbnails(props) {
  var __onSelectExtra = props.onSelectExtra;
  var onSelect = props.onSelect;
  var __imageSize = props.imageSize;
  var __maxLength = props.maxLength;
  var selectedIndex = props.selectedIndex;
  var images = props.images;
  var __highlightSelected = props.highlightSelected;
  var highlightSelected = __highlightSelected !== undefined ? __highlightSelected : true;
  var maxLength = __maxLength !== undefined ? __maxLength : 12;
  var imageSize = __imageSize !== undefined ? __imageSize : 75;
  var onSelectExtra = __onSelectExtra !== undefined ? __onSelectExtra : (function (prim) {
        
      });
  var makeThumbnail = function (img, i) {
    return JsxRuntime.jsx(Touchable__Jsx3.make, {
                style: thumbnailStyles(highlightSelected, imageSize, selectedIndex === i),
                onPress: (function (param) {
                    Curry._1(onSelect, i);
                  }),
                children: JsxRuntime.jsx("img", {
                      height: String(imageSize),
                      src: img.thumb,
                      width: String(imageSize)
                    })
              }, img.thumb);
  };
  return JsxRuntime.jsx("div", {
              children: images.length > (maxLength + 1 | 0) ? images.filter(function (param, i) {
                          return i < maxLength;
                        }).map(makeThumbnail).concat([JsxRuntime.jsx(Touchable__Jsx3.make, {
                            style: thumbnailStyles(highlightSelected, imageSize, selectedIndex >= maxLength),
                            onPress: (function (param) {
                                Curry._1(onSelect, maxLength);
                                Curry._1(onSelectExtra, undefined);
                              }),
                            children: JsxRuntime.jsx("div", {
                                  children: "+" + String(images.length - maxLength | 0)
                                })
                          }, "extra")]) : images.map(makeThumbnail),
              className: Curry._1(Css.style, gridStyles(imageSize))
            });
}

var ImageThumbnails = {
  View: undefined,
  thumbnailStyles: thumbnailStyles,
  gridStyles: gridStyles,
  make: TMPUIKIT____ImgGallery$ImageThumbnails
};

var onImage = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top({
              NAME: "px",
              VAL: 10
            }),
        tl: {
          hd: Css.left({
                NAME: "px",
                VAL: 10
              }),
          tl: {
            hd: Css.padding({
                  NAME: "px",
                  VAL: 5
                }),
            tl: {
              hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                        NAME: "num",
                        VAL: 0.4
                      })),
              tl: {
                hd: Css.color({
                      NAME: "rgb",
                      VAL: [
                        255,
                        255,
                        255
                      ]
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var aboveImage = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top({
              NAME: "px",
              VAL: -40
            }),
        tl: {
          hd: Css.padding({
                NAME: "px",
                VAL: 5
              }),
          tl: {
            hd: Css.color({
                  NAME: "rgb",
                  VAL: [
                    255,
                    255,
                    255
                  ]
                }),
            tl: /* [] */0
          }
        }
      }
    });

function TMPUIKIT____ImgGallery$SlideNumber(props) {
  return JsxRuntime.jsx("div", {
              children: String(props.index + 1 | 0) + (" of " + (String(props.length) + " photos")),
              className: props.position === "onImage" ? onImage : aboveImage
            });
}

var SlideNumber = {
  onImage: onImage,
  aboveImage: aboveImage,
  make: TMPUIKIT____ImgGallery$SlideNumber
};

var openModalButtonStyle_0 = Css.position("absolute");

var openModalButtonStyle_1 = {
  hd: Css.top({
        NAME: "px",
        VAL: 10
      }),
  tl: {
    hd: Css.right({
          NAME: "px",
          VAL: 10
        }),
    tl: {
      hd: Css.padding({
            NAME: "px",
            VAL: 5
          }),
      tl: {
        hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                  NAME: "num",
                  VAL: 0.4
                })),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.borderRadius({
                  NAME: "px",
                  VAL: 3
                }),
            tl: {
              hd: Css.zIndex(1),
              tl: {
                hd: Css.color(Css.rgb(255, 255, 255)),
                tl: {
                  hd: Css.hover({
                        hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                                  NAME: "num",
                                  VAL: 0.5
                                })),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var openModalButtonStyle = {
  hd: openModalButtonStyle_0,
  tl: openModalButtonStyle_1
};

var nextButton = JsxRuntime.jsxs(JsxRuntime.Fragment, {
      children: [
        JsxRuntime.jsx(VisuallyHidden, {
              children: "Next"
            }),
        JsxRuntime.jsx(Icon__Jsx3.make, {
              icon: "ArrowRight"
            })
      ]
    });

var prevButton = JsxRuntime.jsxs(JsxRuntime.Fragment, {
      children: [
        JsxRuntime.jsx(VisuallyHidden, {
              children: "Previous"
            }),
        JsxRuntime.jsx(Icon__Jsx3.make, {
              icon: "ArrowLeft"
            })
      ]
    });

var defaultControlsConfig_containerClassName = defaultControlsConfig.containerClassName;

var defaultControlsConfig_nextButtonClassName = defaultControlsConfig.nextButtonClassName;

var defaultControlsConfig_nextButtonStyle = defaultControlsConfig.nextButtonStyle;

var defaultControlsConfig_prevButtonClassName = defaultControlsConfig.prevButtonClassName;

var defaultControlsConfig_prevButtonStyle = defaultControlsConfig.prevButtonStyle;

var defaultControlsConfig_pagingDotsContainerClassName = defaultControlsConfig.pagingDotsContainerClassName;

var defaultControlsConfig_pagingDotsClassName = defaultControlsConfig.pagingDotsClassName;

var defaultControlsConfig_pagingDotsStyle = defaultControlsConfig.pagingDotsStyle;

var defaultControlsConfig$1 = {
  containerClassName: defaultControlsConfig_containerClassName,
  nextButtonClassName: defaultControlsConfig_nextButtonClassName,
  nextButtonStyle: defaultControlsConfig_nextButtonStyle,
  nextButtonText: nextButton,
  prevButtonClassName: defaultControlsConfig_prevButtonClassName,
  prevButtonStyle: defaultControlsConfig_prevButtonStyle,
  prevButtonText: prevButton,
  pagingDotsContainerClassName: defaultControlsConfig_pagingDotsContainerClassName,
  pagingDotsClassName: defaultControlsConfig_pagingDotsClassName,
  pagingDotsStyle: defaultControlsConfig_pagingDotsStyle
};

var styles = Curry._1(Css.style, {
      hd: Css.selector(".slider-control-centerleft > button, .slider-control-centerright > button", {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: Css.selector(".slider-list", {
              hd: Css.important(Css.cursor("default")),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.selector(".slider-slide", {
                hd: Css.important(Css.display("inlineFlex")),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.selector(".slider .paging-item button", {
                  hd: Css.padding2({
                        NAME: "px",
                        VAL: 1
                      }, {
                        NAME: "px",
                        VAL: 6
                      }),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

function TMPUIKIT____ImgGallery$Carousel(props) {
  var imgSize = props.imgSize;
  var __maxThumbnails = props.maxThumbnails;
  var selectedIndex = props.selectedIndex;
  var onSelect = props.onSelect;
  var openModal = props.openModal;
  var images = props.images;
  var maxThumbnails = __maxThumbnails !== undefined ? __maxThumbnails : 24;
  var windowSize = UseWindowSize.useWindowSize();
  var className = React.useMemo((function (param) {
          var maxDots = String(imgSize === "small" ? Math.min(windowSize.width - 50 | 0, 668) / 20 | 0 : (windowSize.width - 30 | 0) / 20 | 0);
          return styles + (" " + Curry._1(Css.style, {
                        hd: Css.selector(".slider .paging-item:nth-child(n+" + maxDots + ")", {
                              hd: Css.display("none"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }));
        }), [
        windowSize.width,
        imgSize
      ]);
  return JsxRuntime.jsxs(ViewStack.make, {
              alignItems: "center",
              flexDirection: "column",
              position: "relative",
              className: className,
              children: [
                openModal !== undefined ? JsxRuntime.jsx(Touchable__Jsx3.make, {
                        style: openModalButtonStyle,
                        onPress: (function (param) {
                            Curry._1(openModal, undefined);
                          }),
                        children: JsxRuntime.jsx(Icon__Jsx3.make, {
                              icon: "FullSizeView"
                            })
                      }) : null,
                JsxRuntime.jsx(NukaCarousel, {
                      beforeSlide: (function (_prev, nextIndex) {
                          Curry._1(onSelect, nextIndex);
                        }),
                      children: images.map(function (img, index) {
                            return JsxRuntime.jsx(TMPUIKIT____ImgGallery$ResponsiveImage, {
                                        image: img,
                                        alt: String(index),
                                        imgSize: imgSize
                                      }, img.thumb);
                          }),
                      defaultControlsConfig: defaultControlsConfig$1,
                      enableKeyboardControls: true,
                      slideIndex: selectedIndex
                    }, String(windowSize.width)),
                JsxRuntime.jsx(TMPUIKIT____ImgGallery$SlideNumber, {
                      index: selectedIndex,
                      length: images.length,
                      position: imgSize === "fullscreen" ? "aboveImage" : "onImage"
                    }),
                JsxRuntime.jsx(TMPUIKIT____ImgGallery$ImageThumbnails, {
                      images: images,
                      selectedIndex: selectedIndex,
                      maxLength: maxThumbnails,
                      onSelect: onSelect,
                      onSelectExtra: openModal
                    })
              ]
            });
}

var Carousel = {
  openModalButtonStyle: openModalButtonStyle,
  nextButton: nextButton,
  prevButton: prevButton,
  defaultControlsConfig: defaultControlsConfig$1,
  styles: styles,
  make: TMPUIKIT____ImgGallery$Carousel
};

var className = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top({
              NAME: "px",
              VAL: 10
            }),
        tl: {
          hd: Css.left({
                NAME: "px",
                VAL: 10
              }),
          tl: {
            hd: Css.padding({
                  NAME: "px",
                  VAL: 5
                }),
            tl: {
              hd: Css.backgroundColor(Css.rgba(34, 34, 34, {
                        NAME: "num",
                        VAL: 0.66
                      })),
              tl: {
                hd: Css.color({
                      NAME: "rgb",
                      VAL: [
                        255,
                        255,
                        255
                      ]
                    }),
                tl: {
                  hd: Css.fontSize({
                        NAME: "px",
                        VAL: 12
                      }),
                  tl: {
                    hd: Css.fontWeight({
                          NAME: "num",
                          VAL: 500
                        }),
                    tl: {
                      hd: Css.borderRadius({
                            NAME: "px",
                            VAL: 5
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function TMPUIKIT____ImgGallery$LightCarousel$LightSlideNumber(props) {
  return JsxRuntime.jsx("div", {
              children: String(props.index + 1 | 0) + (" / " + String(props.length)),
              className: className
            });
}

var LightSlideNumber = {
  className: className,
  make: TMPUIKIT____ImgGallery$LightCarousel$LightSlideNumber
};

function TMPUIKIT____ImgGallery$LightCarousel(props) {
  var selectedIndex = props.selectedIndex;
  var onSelect = props.onSelect;
  var openModal = props.openModal;
  var images = props.images;
  var windowSize = UseWindowSize.useWindowSize();
  return JsxRuntime.jsxs(View__Jsx3.make, {
              alignItems: "center",
              flexDirection: "column",
              position: "relative",
              className: styles,
              children: [
                JsxRuntime.jsx(NukaCarousel, {
                      beforeSlide: (function (_prev, nextIndex) {
                          Curry._1(onSelect, nextIndex);
                        }),
                      children: images.map(function (img) {
                            return JsxRuntime.jsx(Touchable__Jsx3.make, {
                                        style: {
                                          hd: Css.width({
                                                NAME: "percent",
                                                VAL: 100
                                              }),
                                          tl: {
                                            hd: Css.height({
                                                  NAME: "percent",
                                                  VAL: 100
                                                }),
                                            tl: /* [] */0
                                          }
                                        },
                                        onPress: (function (param) {
                                            Curry._1(openModal, undefined);
                                          }),
                                        children: JsxRuntime.jsx(TMPUIKIT____ImgGallery$ResponsiveImage, {
                                              image: img,
                                              alt: "",
                                              imgSize: "small"
                                            })
                                      }, img.thumb);
                          }),
                      enableKeyboardControls: true,
                      slideIndex: selectedIndex,
                      withoutControls: true
                    }, String(windowSize.width)),
                JsxRuntime.jsx(TMPUIKIT____ImgGallery$LightCarousel$LightSlideNumber, {
                      index: selectedIndex,
                      length: images.length
                    })
              ]
            });
}

var LightCarousel = {
  LightSlideNumber: LightSlideNumber,
  make: TMPUIKIT____ImgGallery$LightCarousel
};

var $neg$neg = Css_Legacy_Core.Calc.$neg;

var carouselHolder = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignContent("stretch"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.margin("auto"),
              tl: {
                hd: Css.width({
                      NAME: "percent",
                      VAL: 100
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var modal = Curry._1(Css.style, {
      hd: Css.margin("auto"),
      tl: {
        hd: Css.backgroundColor("transparent"),
        tl: {
          hd: Css.margin("zero"),
          tl: {
            hd: Css.padding("zero"),
            tl: {
              hd: Css.width({
                    NAME: "vw",
                    VAL: 100
                  }),
              tl: {
                hd: Css.maxWidth({
                      NAME: "vh",
                      VAL: 120
                    }),
                tl: {
                  hd: Css.selector(".slider-slide div", {
                        hd: Css.height(Curry._2($neg$neg, {
                                  NAME: "vh",
                                  VAL: 100
                                }, {
                                  NAME: "px",
                                  VAL: 190
                                })),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexGrow(2),
                      tl: {
                        hd: Css.marginBottom({
                              NAME: "px",
                              VAL: 20
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var overlay = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              0,
              0,
              0,
              {
                NAME: "num",
                VAL: 0.95
              }
            ]
          }),
      tl: {
        hd: Css.backdropFilter({
              hd: {
                NAME: "blur",
                VAL: {
                  NAME: "px",
                  VAL: 3
                }
              },
              tl: {
                hd: {
                  NAME: "brightness",
                  VAL: {
                    NAME: "percent",
                    VAL: 80
                  }
                },
                tl: {
                  hd: {
                    NAME: "saturate",
                    VAL: {
                      NAME: "percent",
                      VAL: 30
                    }
                  },
                  tl: /* [] */0
                }
              }
            }),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.unsafe("backdropFilter", "blur(3px) brightness(80%) saturate(30%)"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Styles = {
  $neg$neg: $neg$neg,
  carouselHolder: carouselHolder,
  modal: modal,
  overlay: overlay
};

function TMPUIKIT____ImgGallery$CarouselDialog(props) {
  var onDismiss = props.onDismiss;
  return JsxRuntime.jsxs(UIKit__BaseDialog.Animated.make, {
              ariaLabel: "Image Gallery",
              isOpen: props.isOpen,
              onDismiss: onDismiss,
              overlayClassName: overlay,
              contentClassName: modal,
              closeButton: Caml_option.some(JsxRuntime.jsxs(Touchable__Jsx3.make, {
                        style: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.focus({
                                  hd: Css.outlineStyle("none"),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.alignSelf("flexEnd"),
                              tl: /* [] */0
                            }
                          }
                        },
                        p: {
                          NAME: "px",
                          VAL: 20
                        },
                        onPress: (function (param) {
                            Curry._1(onDismiss, undefined);
                          }),
                        children: [
                          JsxRuntime.jsx(VisuallyHidden, {
                                children: "Close"
                              }),
                          JsxRuntime.jsx(Icon__Jsx3.make, {
                                icon: "Cancel",
                                size: 24,
                                color: Theme__Color__Vars.white
                              })
                        ]
                      })),
              transitionOnDestroyed: props.transitionOnDestroyed,
              children: [
                JsxRuntime.jsx(View__Jsx3.make, {
                      justifyContent: "flexEnd"
                    }),
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: carouselHolder
                    })
              ]
            });
}

var CarouselDialog = {
  Styles: Styles,
  make: TMPUIKIT____ImgGallery$CarouselDialog
};

function TMPUIKIT____ImgGallery$GalleryDialog(props) {
  var selectedIndex = props.selectedIndex;
  var match = React.useState(function (param) {
        return selectedIndex;
      });
  var setSelected = match[1];
  React.useEffect((function (param) {
          Curry._1(setSelected, (function (param) {
                  return selectedIndex;
                }));
        }), [selectedIndex]);
  return JsxRuntime.jsx(TMPUIKIT____ImgGallery$CarouselDialog, {
              isOpen: props.isOpen,
              onDismiss: props.onDismiss,
              children: JsxRuntime.jsx(TMPUIKIT____ImgGallery$Carousel, {
                    images: props.images,
                    onSelect: (function (i) {
                        Curry._1(setSelected, (function (param) {
                                return i;
                              }));
                      }),
                    selectedIndex: match[0],
                    imgSize: "fullscreen"
                  })
            });
}

var GalleryDialog = {
  make: TMPUIKIT____ImgGallery$GalleryDialog
};

exports.Nuka = Nuka;
exports.CarouselImg = CarouselImg;
exports.ResponsiveImage = ResponsiveImage;
exports.ImageThumbnails = ImageThumbnails;
exports.SlideNumber = SlideNumber;
exports.Carousel = Carousel;
exports.LightCarousel = LightCarousel;
exports.CarouselDialog = CarouselDialog;
exports.GalleryDialog = GalleryDialog;
/* imageContainerStyle Not a pure module */
