// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

function scrollY(param) {
  return window.scrollY;
}

function scrollX(param) {
  return window.scrollX;
}

function setScroll(x, y) {
  window.scroll(x, y);
}

function scrollPosition(param) {
  return [
          window.scrollX,
          window.scrollY
        ];
}

function scrollToTop(param) {
  setScroll(0, 0);
}

var _map = {"auto":"auto","smooth":"smooth"};

var _revMap = {"auto":"auto","smooth":"smooth"};

function behaviorToJs(param) {
  return _map[param];
}

function behaviorFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"start":"start","center":"center","end_":"end","nearest":"nearest"};

var _revMap$1 = {"start":"start","center":"center","end":"end_","nearest":"nearest"};

function alignmentToJs(param) {
  return _map$1[param];
}

function alignmentFromJs(param) {
  return _revMap$1[param];
}

function toJs(v) {
  return {
          behavior: _map[v.behavior],
          block: _map$1[v.block]
        };
}

var Options = {
  behaviorToJs: behaviorToJs,
  behaviorFromJs: behaviorFromJs,
  alignmentToJs: alignmentToJs,
  alignmentFromJs: alignmentFromJs,
  toJs: toJs
};

function scrollToHash(hash, options, param) {
  var $$location = hash !== undefined ? hash : window.location.hash;
  if ($$location === "") {
    return ;
  }
  var match = document.querySelector($$location);
  if (!(match == null)) {
    if (options !== undefined) {
      match.scrollIntoView(toJs(options));
    } else {
      match.scrollIntoView();
    }
    return ;
  }
  
}

function isElementInView(element) {
  var boundingRect = element.getBoundingClientRect();
  if (boundingRect.top >= 0 && boundingRect.left >= 0 && boundingRect.right <= window.innerWidth) {
    return boundingRect.bottom <= window.innerHeight;
  } else {
    return false;
  }
}

function scrollToHashIfNeeded(hash, options, param) {
  var $$location = hash !== undefined ? hash : window.location.hash;
  if ($$location === "") {
    return ;
  }
  var match = Core__Option.map(Caml_option.nullable_to_opt(document.querySelector($$location)), (function (el) {
          return [
                  el,
                  isElementInView(el)
                ];
        }));
  if (match === undefined) {
    return ;
  }
  if (match[1]) {
    return ;
  }
  var el = match[0];
  if (options !== undefined) {
    el.scrollIntoView(toJs(options));
  } else {
    el.scrollIntoView();
  }
}

var $$Location;

exports.$$Location = $$Location;
exports.scrollY = scrollY;
exports.scrollX = scrollX;
exports.setScroll = setScroll;
exports.scrollPosition = scrollPosition;
exports.scrollToTop = scrollToTop;
exports.Options = Options;
exports.scrollToHash = scrollToHash;
exports.isElementInView = isElementInView;
exports.scrollToHashIfNeeded = scrollToHashIfNeeded;
/* No side effect */
