// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function HtmlHolder(props) {
  var __proseStyle = props.proseStyle;
  var proseStyle = __proseStyle !== undefined ? __proseStyle : "sm";
  var proseClassName = proseStyle === "sm" ? "prose prose-sm" : "prose";
  var classNames = "whitespace-pre-line \n  prose-a:break-all prose-a:whitespace-pre-wrap prose-a:line-clamp-2 prose-a:overflow-hidden \n  prose-zinc prose-ul:list-disc prose-ol:list-decimal\n  " + proseClassName + " [&>p>br:only-child]:hidden first:prose-p:mt-0 first:prose-headings:mt-0 first:prose-ul:mt-0 first:prose-ol:mt-0 \n  last:prose-p:mb-0 last:prose-headings:mb-0 last:prose-ul:mb-0 last:prose-ol:mb-0";
  return JsxRuntime.jsx("div", {
              ref: props.innerRef,
              className: Core__Option.mapOr(props.className, classNames, (function (c) {
                      return classNames + (" " + c);
                    })),
              dangerouslySetInnerHTML: {
                __html: props.innerHtml
              }
            });
}

var make = HtmlHolder;

exports.make = make;
/* react/jsx-runtime Not a pure module */
