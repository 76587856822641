// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Image = require("../../../reason/next/Next__Image.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var TailwindMerge = require("tailwind-merge");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ImageGridImage_image_graphql = require("../../../__generated__/ImageGridImage_image_graphql.bs.js");

var convertFragment = ImageGridImage_image_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ImageGridImage_image_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ImageGridImage_image_graphql.node, convertFragment);
}

var ImageFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function ImageGridImage(props) {
  var imageVariant = props.imageVariant;
  var index = props.index;
  var image = RescriptRelay_Fragment.useFragment(ImageGridImage_image_graphql.node, convertFragment, props.image);
  var match = imageVariant === "medium" ? [
      861,
      574,
      image.medium
    ] : (
      imageVariant === "small" ? [
          426,
          284,
          image.small
        ] : [
          1288,
          720,
          image.large
        ]
    );
  return JsxRuntime.jsx("button", {
              children: JsxRuntime.jsx(Next__Image.make, {
                    src: match[2],
                    width: match[0],
                    height: match[1],
                    unoptimized: true,
                    alt: "",
                    className: "rounded object-cover max-w-full h-auto"
                  }),
              className: TailwindMerge.twMerge([
                    "flex items-stretch rounded cursor-pointer transition-filter duration-150 ease-in hover:brightness-75 hover:ease-out",
                    Core__Option.getOr(props.className, "")
                  ]),
              id: "image" + String(index),
              onClick: props.onPress
            }, "grid-image-" + String(index));
}

var make = ImageGridImage;

exports.ImageFragment = ImageFragment;
exports.make = make;
/* Next__Image Not a pure module */
