// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "kind": "Literal",
  "name": "crop",
  "value": true
},
v1 = {
  "kind": "Variable",
  "name": "ratio",
  "variableName": "__relay_internal__pv__DevicePixelRatio"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__DevicePixelRatio"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageGridImage_image",
  "selections": [
    {
      "alias": "large",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "embed",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "height",
          "value": 720
        },
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "width",
          "value": 1288
        }
      ],
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": "medium",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "height",
          "value": 574
        },
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "width",
          "value": 861
        }
      ],
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": "small",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "height",
          "value": 284
        },
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "width",
          "value": 426
        }
      ],
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "ImageAttachment",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
