// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ImageGridImage = require("./ImageGridImage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ImageGrid_image_graphql = require("../../../__generated__/ImageGrid_image_graphql.bs.js");

var convertFragment = ImageGrid_image_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ImageGrid_image_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(fRef) : undefined, ImageGrid_image_graphql.node, convertFragment);
}

var ImageFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function ImageGrid(props) {
  var onSelectImage = props.onSelectImage;
  var labels = props.labels;
  var images = RescriptRelay_Fragment.useFragment(ImageGrid_image_graphql.node, convertFragment, props.images);
  var imageCount = images.length;
  var tmp;
  if (imageCount > 0) {
    var tmp$1;
    var exit = 0;
    var len = images.length;
    if (len >= 3) {
      exit = 1;
    } else {
      switch (len) {
        case 0 :
            exit = 1;
            break;
        case 1 :
            var img = images[0];
            tmp$1 = JsxRuntime.jsx(ImageGridImage.make, {
                  image: img.fragmentRefs,
                  index: 0,
                  imageVariant: "large",
                  onPress: (function (param) {
                      return Curry._1(onSelectImage, 0);
                    })
                });
            break;
        case 2 :
            var img1 = images[0];
            var img2 = images[1];
            tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                  children: [
                    JsxRuntime.jsx(ImageGridImage.make, {
                          image: img1.fragmentRefs,
                          index: 0,
                          imageVariant: "medium",
                          onPress: (function (param) {
                              return Curry._1(onSelectImage, 0);
                            }),
                          className: "col-start-1 col-end-2"
                        }),
                    JsxRuntime.jsx(ImageGridImage.make, {
                          image: img2.fragmentRefs,
                          index: 1,
                          imageVariant: "medium",
                          onPress: (function (param) {
                              return Curry._1(onSelectImage, 1);
                            }),
                          className: "col-start-2 col-end-3"
                        })
                  ]
                });
            break;
        
      }
    }
    if (exit === 1) {
      var match = images.slice(0, 3);
      if (match.length !== 3) {
        tmp$1 = null;
      } else {
        var img1$1 = match[0];
        var img2$1 = match[1];
        var img3 = match[2];
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ImageGridImage.make, {
                      image: img1$1.fragmentRefs,
                      index: 0,
                      imageVariant: "medium",
                      onPress: (function (param) {
                          return Curry._1(onSelectImage, 0);
                        }),
                      className: "col-start-1 col-end-3 row-start-1 row-end-3"
                    }),
                JsxRuntime.jsx(ImageGridImage.make, {
                      image: img2$1.fragmentRefs,
                      index: 1,
                      imageVariant: "small",
                      onPress: (function (param) {
                          return Curry._1(onSelectImage, 1);
                        }),
                      className: "col-start-3 col-end-4 row-start-1 row-end-2"
                    }),
                JsxRuntime.jsx(ImageGridImage.make, {
                      image: img3.fragmentRefs,
                      index: 2,
                      imageVariant: "small",
                      onPress: (function (param) {
                          return Curry._1(onSelectImage, 2);
                        }),
                      className: "col-start-3 col-end-4 row-start-2 row-end-3"
                    })
              ]
            });
      }
    }
    tmp = JsxRuntime.jsx("div", {
          children: tmp$1,
          className: imageCount !== 1 ? (
              imageCount !== 2 ? "grid grid-cols-3 grid-rows-2 gap-2" : "grid grid-cols-2 gap-2"
            ) : "flex"
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                labels !== undefined ? JsxRuntime.jsx("div", {
                        children: Caml_option.valFromOption(labels),
                        className: "absolute top-2 left-2 z-20"
                      }) : null,
                images.length > 3 ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("button", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Icon__Jsx3.make, {
                                            icon: "Picture",
                                            size: 16
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "View all photos"
                                          })
                                    ],
                                    className: "flex items-center gap-2"
                                  }),
                              className: "bg-white text-gray-700 p-1.5 rounded cursor-pointer text-base font-medium",
                              onClick: props.onViewAll
                            }),
                        className: "absolute bottom-2 right-2 z-20"
                      }) : null,
                tmp
              ],
              className: "relative"
            });
}

var make = ImageGrid;

exports.ImageFragment = ImageFragment;
exports.make = make;
/* Icon__Jsx3 Not a pure module */
