// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "kind": "Variable",
  "name": "ratio",
  "variableName": "__relay_internal__pv__DevicePixelRatio"
},
v1 = {
  "kind": "Literal",
  "name": "width",
  "value": 1200
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__DevicePixelRatio"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ImageGallery_image",
  "selections": [
    {
      "alias": "carouselFullscreen",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": "carouselLarge",
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 800
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": "carouselSmall",
      "args": [
        {
          "kind": "Literal",
          "name": "embed",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "height",
          "value": 500
        },
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "width",
          "value": 750
        }
      ],
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": "carouselThumb",
      "args": [
        {
          "kind": "Literal",
          "name": "crop",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "height",
          "value": 90
        },
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "width",
          "value": 90
        }
      ],
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImageGrid_image"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AllImages_image"
    }
  ],
  "type": "ImageAttachment",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
