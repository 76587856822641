// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Scroll = require("../../../reason/common/Scroll/Scroll.bs.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Image = require("../../../reason/next/Next__Image.bs.js");
var ReactRender = require("../../../reason/utils/ReactRender.bs.js");
var MarkupHelpers = require("../../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var TailwindMerge = require("tailwind-merge");
var UIKit__BaseDialog = require("../../../uikit/reason/molecules/Dialog/UIKit__BaseDialog.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var VisuallyHidden = require("@reach/visually-hidden").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var AllImages_image_graphql = require("../../../__generated__/AllImages_image_graphql.bs.js");

var convertFragment = AllImages_image_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AllImages_image_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(fRef) : undefined, AllImages_image_graphql.node, convertFragment);
}

var ImageFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function AllImages$CloseButton(props) {
  return JsxRuntime.jsxs("button", {
              children: [
                JsxRuntime.jsx(VisuallyHidden, {
                      children: "Close"
                    }),
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx(Icon__Jsx3.make, {
                            icon: "Cancel",
                            size: 16
                          }),
                      "aria-hidden": true,
                      className: "flex"
                    })
              ],
              className: "border-transparent border-solid border cursor-pointer p-2 text-gray-700 hover:border-gray-300 hover:border hover:bg-gray-100 focus-visible:border-gray-300 focus-visible:bg-gray-100 focus-visible:border ",
              onClick: props.onPress
            });
}

var CloseButton = {
  make: AllImages$CloseButton
};

function AllImages(props) {
  var selectedImage = props.selectedImage;
  var isFullscreenCarouselOpen = props.isFullscreenCarouselOpen;
  var isFullscreenCarouselVisible = props.isFullscreenCarouselVisible;
  var onSelectImage = props.onSelectImage;
  var onClose = props.onClose;
  var images = RescriptRelay_Fragment.useFragment(AllImages_image_graphql.node, convertFragment, props.images);
  var match = React.useReducer((function (state, action) {
          switch (state) {
            case "Visible" :
                switch (action) {
                  case "Unrender" :
                      return "NotRendered";
                  case "Render" :
                  case "Show" :
                      return state;
                  
                }
            case "Invisible" :
                switch (action) {
                  case "Unrender" :
                  case "Render" :
                      return state;
                  case "Show" :
                      return "Visible";
                  
                }
            case "NotRendered" :
                switch (action) {
                  case "Render" :
                      return "Invisible";
                  case "Unrender" :
                  case "Show" :
                      return state;
                  
                }
            
          }
        }), "Visible");
  var send = match[1];
  var state = match[0];
  React.useEffect((function (param) {
          switch (state) {
            case "Visible" :
                if (isFullscreenCarouselVisible) {
                  Curry._1(send, "Unrender");
                }
                break;
            case "Invisible" :
                break;
            case "NotRendered" :
                if (isFullscreenCarouselOpen) {
                  
                } else {
                  Curry._1(send, "Render");
                }
                break;
            
          }
        }), [
        isFullscreenCarouselVisible,
        isFullscreenCarouselOpen,
        state
      ]);
  React.useEffect((function (param) {
          switch (state) {
            case "Invisible" :
                if (isFullscreenCarouselVisible) {
                  
                } else if (selectedImage !== undefined) {
                  Scroll.scrollToHashIfNeeded("#image" + String(selectedImage), undefined, undefined);
                  Curry._1(send, "Show");
                } else {
                  Curry._1(send, "Show");
                }
                break;
            case "Visible" :
            case "NotRendered" :
                break;
            
          }
        }), [
        state,
        selectedImage,
        isFullscreenCarouselVisible
      ]);
  var shouldRender;
  switch (state) {
    case "Visible" :
    case "Invisible" :
        shouldRender = true;
        break;
    case "NotRendered" :
        shouldRender = false;
        break;
    
  }
  var onDismiss = React.useCallback((function (param) {
          Curry._1(onClose, undefined);
        }), [onClose]);
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(UIKit__BaseDialog.Animated.make, {
                        ariaLabel: "Photos",
                        isOpen: props.isOpen,
                        onDismiss: onDismiss,
                        overlayClassName: "!bg-white",
                        contentClassName: "!w-full !h-full !mt-0 !pt-0 !pr-0 !pb-25 !pl-0 [WebkitOverflowScrolling:touch]",
                        children: JsxRuntime.jsxs(MarkupHelpers.Container.make, {
                              mobilePadding: false,
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(AllImages$CloseButton, {
                                            onPress: (function (param) {
                                                Curry._1(onClose, undefined);
                                              })
                                          }),
                                      className: "flex justify-end pt-4 pb-2"
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: ReactRender.ifTrue(images.map(function (img, i) {
                                                return JsxRuntime.jsx("button", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: JsxRuntime.jsx(Next__Image.make, {
                                                                        src: img.imgUrl,
                                                                        width: 426,
                                                                        height: 284,
                                                                        unoptimized: true,
                                                                        alt: "",
                                                                        className: "object-cover max-w-full h-auto"
                                                                      }),
                                                                  className: "flex transition-filter duration-150 ease-in hover:brightness-75 hover:ease-out"
                                                                }),
                                                            id: "image" + String(i),
                                                            onClick: (function (param) {
                                                                Curry._1(onSelectImage, i);
                                                              })
                                                          }, img.key);
                                              }), shouldRender),
                                      className: TailwindMerge.twMerge([
                                            "opacity-100 grid grid-cols-2 gap-1 md:grid-cols-3 md:gap-2 transition-opacity duration-200 ease-out",
                                            state === "Visible" ? "" : "opacity-0"
                                          ])
                                    })
                              ]
                            })
                      }))
            });
}

var make = AllImages;

exports.ImageFragment = ImageFragment;
exports.CloseButton = CloseButton;
exports.make = make;
/* react Not a pure module */
